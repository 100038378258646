
  import { Component } from 'vue-property-decorator'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { GenioView } from './GenioView'

@Component({
  components: { PTitle, GTextarea },
})
  export default class ConfigPurchase extends GenioView {
    async save () {
      if (!this.$refs.form.validate()) {
        await this.setFieldError()
        return
      }
      console.log('send data')

      this.$emit('update')
    }
  }
