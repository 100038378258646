var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-form',{ref:"form",staticStyle:{"max-height":"800px","overflow-y":"auto"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('p-title',{attrs:{"label":"Reglas del vehículo"}}),_c('p-subtitle',{attrs:{"label":"Debes indicar cuales son las preferencias de configuración a continuación."}}),_c('v-divider',{staticClass:"my-4"}),_c('g-cost-field',{attrs:{"label":"Año mínimo de antigüedad permitido","required":"","min-digits":1},model:{value:(_vm.formData.maxYear),callback:function ($$v) {_vm.$set(_vm.formData, "maxYear", $$v)},expression:"formData.maxYear"}}),_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('p',[_vm._v("Patente obligatoria")]),_c('v-switch',{staticClass:"mr-4",attrs:{"label":_vm.formData.ppuMandatory ? 'Si' : 'No',"true-value":true,"false-value":false},model:{value:(_vm.formData.ppuMandatory),callback:function ($$v) {_vm.$set(_vm.formData, "ppuMandatory", $$v)},expression:"formData.ppuMandatory"}})],1),_c('v-text-field',{attrs:{"label":"Letra de patente valida","required":"","g-mask":{
        mask: 'C',
        tokens: {
          C: {
            pattern: /[a-zA-Z]/,
            transform: (char) => char.toUpperCase(),
          },
        },
      }},model:{value:(_vm.formData.validPpuLetter),callback:function ($$v) {_vm.$set(_vm.formData, "validPpuLetter", $$v)},expression:"formData.validPpuLetter"}}),_c('v-text-field',{attrs:{"label":"Tasa de municipio %","type":"number","required":"","rules":_vm.fieldRequired},model:{value:(_vm.formData.municipalityTax),callback:function ($$v) {_vm.$set(_vm.formData, "municipalityTax", $$v)},expression:"formData.municipalityTax"}}),_c('g-cost-field',{attrs:{"label":"Costo de transferencia","required":""},model:{value:(_vm.formData.fixedTransferCosts),callback:function ($$v) {_vm.$set(_vm.formData, "fixedTransferCosts", $$v)},expression:"formData.fixedTransferCosts"}}),_c('g-cost-field',{attrs:{"label":"Máximo de años de antigüedad permitido en el formulario de creación de auto para el lead","required":"","min-digits":1},model:{value:(_vm.formData.maxVehicleAgeAllowed),callback:function ($$v) {_vm.$set(_vm.formData, "maxVehicleAgeAllowed", $$v)},expression:"formData.maxVehicleAgeAllowed"}})],1),_c('v-divider',{staticClass:"my-2"}),_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Publicar")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }