import { render, staticRenderFns } from "./ConfigMaintenance.vue?vue&type=template&id=741a04bc&scoped=true&"
import script from "./ConfigMaintenance.vue?vue&type=script&lang=ts&"
export * from "./ConfigMaintenance.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigMaintenance.vue?vue&type=style&index=0&id=741a04bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741a04bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VDivider,VForm})
