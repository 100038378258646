
  import { Component, Watch } from 'vue-property-decorator'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { GenioView } from './GenioView'
  import PSubtitle from '../PSubtitle.vue'
  import GCostField from '@/components/core/input/GCostField.vue'

@Component({
  components: { PTitle, GTextarea, PSubtitle, GCostField },
})
  export default class ConfigVehicle extends GenioView {
  formData = {
    maxYear: 14,
    ppuMandatory: true,
    validPpuLetter: 'A',
    municipalityTax: 0.015,
    fixedTransferCosts: 130000,
    maxVehicleAgeAllowed: 3,
  }

  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    await this.pushData({
      model: 'Process',
      fields: {
        id: this.process.id,
        config: {
          maxYear: this.formData.maxYear,
          ppuMandatory: this.formData.ppuMandatory,
          validPpuLetter: this.formData.validPpuLetter,
          municipalityTax: this.formData.municipalityTax,
          fixedTransferCosts: parseInt(this.formData.fixedTransferCosts.toString()),
          maxVehicleAgeAllowed: parseInt(this.formData.maxVehicleAgeAllowed.toString()),
        },
      },
    })

    console.log('send data')

    this.$emit('update')
  }

  @Watch('process', { immediate: true, deep: true })
  onConfigChange (newValue: any) {
    if (newValue?.config) {
      this.formData = {
        maxYear: newValue?.config?.maxYear,
        ppuMandatory: newValue?.config?.ppuMandatory,
        validPpuLetter: newValue?.config?.validPpuLetter,
        municipalityTax: newValue?.config?.municipalityTax,
        fixedTransferCosts: newValue?.config?.fixedTransferCosts,
        maxVehicleAgeAllowed: newValue?.config?.maxVehicleAgeAllowed,
      }
    }
  }
  }
