
  import { Component, Watch } from 'vue-property-decorator'
  import GTextarea from '@/components/core/input/GTextarea.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { GenioView } from './GenioView'
  import GCostField from '@/components/core/input/GCostField.vue'
  import PSubtitle from '@/components/config/PSubtitle.vue'

@Component({
  components: { PTitle, PSubtitle, GTextarea, GCostField },
})
  export default class ConfigLead extends GenioView {
  formData = {
    // Ya existente
    settings: {
      uniqueExecutive: true,
    },
    projectedUtility: {
      quantity: 900,
    },

    // Nuevos grupos de configuración
    requiredFields: {
      interestLevel: false,
      preferenceContact: false,
      initialNote: false,
    },
    creationAssignment: {
      automaticExecution: false,
      randomAssignment: false,
      assignWithLongestTimeWithoutLeads: false,
      allowDuplicatedLeads: false,
      blockAssignmentBySupervisor: false,
      blockAssignmentByForwarder: false,
      automaticAssignment: false,
      random: false,
      executiveFewerLeadsInManagement: false,
      executiveFewerLeadsDelayed: false,
    },
    reassignation: {
      leadTimeWithoutManagement: 0,
      blockByBadManagement: false,
      maxBadManagementLeads: 0,
      maxDelayedLeads: 0,
    },
  }

  async save () {
    if (!this.$refs.form.validate()) {
      await this.setFieldError()
      return
    }

    // Preparar objeto config con todos los campos
    const config = {
      settings: {
        uniqueExecutive: this.formData.settings.uniqueExecutive,
      },
      projectedUtility: {
        quantity: parseInt(this.formData.projectedUtility.quantity.toString()),
      },
      requiredFields: {
        interestLevel: this.formData.requiredFields.interestLevel,
        preferenceContact: this.formData.requiredFields.preferenceContact,
        initialNote: this.formData.requiredFields.initialNote,
      },
      creationAssignment: {
        automaticExecution: this.formData.creationAssignment.automaticExecution,
        randomAssignment: this.formData.creationAssignment.randomAssignment,
        assignWithLongestTimeWithoutLeads: this.formData.creationAssignment.assignWithLongestTimeWithoutLeads,
        allowDuplicatedLeads: this.formData.creationAssignment.allowDuplicatedLeads,
        blockAssignmentBySupervisor: this.formData.creationAssignment.blockAssignmentBySupervisor,
        blockAssignmentByForwarder: this.formData.creationAssignment.blockAssignmentByForwarder,
        automaticAssignment: this.formData.creationAssignment.automaticAssignment,
        random: this.formData.creationAssignment.random,
        executiveFewerLeadsDelayed: this.formData.creationAssignment.executiveFewerLeadsDelayed,
        executiveFewerLeadsInManagement: this.formData.creationAssignment.executiveFewerLeadsInManagement,
      },
      reassignation: {
        leadTimeWithoutManagement: parseInt(this.formData.reassignation.leadTimeWithoutManagement.toString()),
        blockByBadManagement: this.formData.reassignation.blockByBadManagement,
        maxBadManagementLeads: parseInt(this.formData.reassignation.maxBadManagementLeads.toString()),
        maxDelayedLeads: parseInt(this.formData.reassignation.maxDelayedLeads.toString()),
      },
    }

    // Envía la data al backend
    await this.pushData({
      model: 'Process',
      fields: {
        id: this.process.id,
        config,
      },
    })

    console.log('send data')
    this.$emit('update')
  }

  @Watch('process', { immediate: true, deep: true })
  onConfigChange (newValue: any) {
    if (newValue?.config) {
      this.formData = {
        settings: {
          uniqueExecutive: newValue?.config?.settings?.uniqueExecutive,
        },
        projectedUtility: {
          quantity: newValue?.config?.projectedUtility?.quantity,
        },
        requiredFields: {
          interestLevel: newValue?.config?.requiredFields?.interestLevel,
          preferenceContact: newValue?.config?.requiredFields?.preferenceContact,
          initialNote: newValue?.config?.requiredFields?.initialNote,
        },
        creationAssignment: {
          automaticExecution: newValue?.config?.creationAssignment?.automaticExecution,
          randomAssignment: newValue?.config?.creationAssignment?.randomAssignment,
          assignWithLongestTimeWithoutLeads: newValue?.config?.creationAssignment?.assignWithLongestTimeWithoutLeads,
          allowDuplicatedLeads: newValue?.config?.creationAssignment?.allowDuplicatedLeads,
          blockAssignmentBySupervisor: newValue?.config?.creationAssignment?.blockAssignmentBySupervisor,
          blockAssignmentByForwarder: newValue?.config?.creationAssignment?.blockAssignmentByForwarder,
          automaticAssignment: newValue?.config?.creationAssignment?.automaticAssignment,
          random: newValue?.config?.creationAssignment?.random,
          executiveFewerLeadsInManagement: newValue?.config?.creationAssignment?.executiveFewerLeadsInManagement,
          executiveFewerLeadsDelayed: newValue?.config?.creationAssignment?.executiveFewerLeadsDelayed,
        },
        reassignation: {
          leadTimeWithoutManagement: newValue?.config?.reassignation?.leadTimeWithoutManagement,
          blockByBadManagement: newValue?.config?.reassignation?.blockByBadManagement,
          maxBadManagementLeads: newValue?.config?.reassignation?.maxBadManagementLeads,
          maxDelayedLeads: newValue?.config?.reassignation?.maxOverdueLeads,
        },
      }
    }
  }
  }
