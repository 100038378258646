
  import ConfigAppraisal from '@/components/config/genio/ConfigAppraisal.vue'
  import ConfigEnablement from '@/components/config/genio/ConfigEnablement.vue'
  import ConfigEvaluation from '@/components/config/genio/ConfigEvaluation.vue'
  import ConfigFinancing from '@/components/config/genio/ConfigFinancing.vue'
  import ConfigInspection from '@/components/config/genio/ConfigInspection.vue'
  import ConfigLead from '@/components/config/genio/ConfigLead.vue'
  import ConfigLeadActivity from '@/components/config/genio/ConfigLeadActivity.vue'
  import ConfigMaintenance from '@/components/config/genio/ConfigMaintenance.vue'
  import ConfigNegotiation from '@/components/config/genio/ConfigNegotiation.vue'
  import ConfigPayment from '@/components/config/genio/ConfigPayment.vue'
  import ConfigPurchase from '@/components/config/genio/ConfigPurchase.vue'
  import ConfigReserve from '@/components/config/genio/ConfigReserve.vue'
  import ConfigSale from '@/components/config/genio/ConfigSale.vue'
  import ConfigStock from '@/components/config/genio/ConfigStock.vue'
  import ConfigVehicle from '@/components/config/genio/ConfigVehicle.vue'
  import PTitle from '@/components/config/PTitle.vue'
  import { GForm } from '@/components/forms/GForm'
  import { Process } from '@/entities/settings'
  import { isValidNumber } from '@/utils/general'
  import { Component, Watch } from 'vue-property-decorator'

@Component({
  components: { PTitle, ConfigAppraisal, ConfigEnablement, ConfigEvaluation, ConfigFinancing, ConfigInspection, ConfigLead, ConfigLeadActivity, ConfigMaintenance, ConfigNegotiation, ConfigPayment, ConfigPurchase, ConfigReserve, ConfigSale, ConfigStock, ConfigVehicle },
})
  export default class SystemConfig extends GForm {
// Lista de procesos (cada proceso tiene su config y configMessage)
process: Process[] = []

// Índice seleccionado en la lista de la izquierda
selected = null

// Búsqueda en la lista de procesos
search = null

// Control de paneles abiertos en la vista principal
panelsOpen = [0]

// Control de paneles abiertos en el nivel de sub-secciones
panelsOpenSection = [0]

// Objeto donde se almacenan el proceso actual y los valores editables
formData = {
  process: null as any,
  values: {} as Record<string, any>,
}

/**
 * Computed: Retorna solo los campos de primer nivel (sin secciones ni mensajería).
 */
get topLevelFields () {
  const { process } = this.formData
  const fieldsArray = process?.configMessage?.fields || []
  return fieldsArray.filter((field: any) => !field.sections && !field.messaging)
}

/**
 * Computed: Retorna las secciones o bloques que sí contienen "sections" o "messaging".
 */
get sectionFields () {
  const { process } = this.formData
  const fieldsArray = process?.configMessage?.fields || []
  return fieldsArray.filter((field: any) => field.sections || field.messaging)
}

/**
 * Computed: Filtra la lista de procesos de acuerdo a la búsqueda actual.
 * Además, sincroniza el índice seleccionado.
 */
get filteredProcess () {
  if (!this.search?.length) {
    const lastProcess = this.formData.process
    this.selected = this.process.findIndex(p => p.id === lastProcess?.id)
    return this.process
  }
  return this.process.filter((p: Process) =>
    p.description.toLowerCase().includes(this.search?.toLowerCase())
  )
}

/**
 * Ciclo de vida: Al montar, busca los procesos con configMessage.
 */
async mounted () {
  this.process = await this.fetchData({
    query: { name: 'find', model: 'Process', order: { order: 'asc' } },
    filter: { config: { _is_null: false } },
    force: true,
  })

  // Selecciona el primero
  this.formData.process = this.process[0]
  this.selected = 0

  // Inicializa los campos si hay un proceso seleccionado
  if (this.formData.process) {
    this.initializeFields()
  }
}

async refreshConfig () {
    await this.loadNotification({
      // @ts-ignore
      message: `Página web actualizada`,
      notificationType: 'success',
    })
  }

/**
 * Carga inicial de los campos en el panel derecho.
 */
initializeFields () {
  const { process } = this.formData
  if (!process?.configMessage?.fields) return
  // this.formData.values =
  console.log(this.formData)
}

/**
 * Función que se llama al presionar "Actualizar".
 * Copia los valores editados a un nuevo config y los guarda en la base.
 */
async send () {
  console.log('a ja')
  await this.loadNotification({
    // @ts-ignore
    message: `Proceso actualizado`,
    notificationType: 'success',
  })
}

/**
 * Watcher: Cuando cambia el índice seleccionado, actualiza formData.process.
 */
@Watch('selected', { immediate: true })
onSelectedChange (val) {
  if (!isValidNumber(val)) {
    this.selected = 0
    this.formData.process = this.process[0]
    return
  }
  this.formData.process = this.filteredProcess[val]
  this.initializeFields()
}
  }
